export type PermissionType = "read" | "update" | "delete" | "add";

export type ISystemAccessPermission = string[];

export const checkSystemAccessPermission = (
    currentSelectedLicensePermissions: ISystemAccessPermission
) => {
    if (currentSelectedLicensePermissions.includes("system:access")) {
        return true;
    }
    return false;
};
export interface ICheckProductPermission {
    currentSelectedLicensePermissions: string[];
    permissionId: string;
}

export interface ICheckLicensePermission {
    currentSelectedLicensePermissions: string[];
    permissionType: PermissionType;
}

export const checkLicensePermission = ({
    currentSelectedLicensePermissions,
    permissionType,
}: ICheckLicensePermission) => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    if (
        currentSelectedLicensePermissions.includes("license:owner") ||
        currentSelectedLicensePermissions.includes("license:admin")
    ) {
        return true;
    }

    const permissionId = `license:${permissionType}`;
    if (currentSelectedLicensePermissions.includes(permissionId)) {
        return true;
    }
    return false;
};
export const checkFormsPermission = (
    currentSelectedLicensePermissions: string[],
    permissionType: string
): boolean => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    const permissionId = `forms:${permissionType}`;
    if (currentSelectedLicensePermissions.includes(permissionId)) {
        return true;
    }
    return false;
};

export const checkAdapterPermission = (
    currentSelectedLicensePermissions: string[],
    permissionType: string
): boolean => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    const permissionId = `data:${permissionType}`;
    if (currentSelectedLicensePermissions.includes(permissionId)) {
        return true;
    }
    return false;
};

export const checkLicenseUsersPermission = (
    currentSelectedLicensePermissions: string[],
    permissionType: string
): boolean => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    const permissionId = `licenseusers:${permissionType}`;
    if (currentSelectedLicensePermissions.includes(permissionId)) {
        return true;
    }
    return false;
};

export const checkDataStorePermission = (
    currentSelectedLicensePermissions: string[],
    permissionType: string
): boolean => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    const permissionId = `datastore:${permissionType}`;
    if (currentSelectedLicensePermissions.includes(permissionId)) {
        return true;
    }
    return false;
};

export const checkProductPermission = ({
    currentSelectedLicensePermissions,
    permissionId,
}: ICheckProductPermission) => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    if (permissionId === "license:read") {
        return checkLicensePermission({
            currentSelectedLicensePermissions,
            permissionType: "read",
        });
    }

    if (currentSelectedLicensePermissions.includes(permissionId)) {
        return true;
    }
    return false;
};

export const checkCMSPermission = (
    currentSelectedLicensePermissions: string[]
) => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    return currentSelectedLicensePermissions.includes("cms:admin");
};

export const checkCMSGuardPermission = (
    currentSelectedLicensePermissions: string[]
) => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    if (currentSelectedLicensePermissions.includes("cms:admin")) {
        return true;
    }

    return currentSelectedLicensePermissions.includes("cms:read");
};

export const checkCMSPermissionScope = ({
    currentSelectedLicensePermissions,
    scope,
}: {
    currentSelectedLicensePermissions: string[];
    scope: string;
}) => {
    if (checkSystemAccessPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    if (checkCMSPermission(currentSelectedLicensePermissions)) {
        return true;
    }

    if (scope.includes('read') && !!currentSelectedLicensePermissions.includes('cms:read')) {
        return true;
    }

    if (currentSelectedLicensePermissions.includes(`${scope}`)) {
        return true;
    }
    return false;
};


